import { Card, Typography, Button, Box } from "@mui/material";

export default function DeleteCard(props: {
  onClose: () => void;
  text: string;
}) {
  return (
    <Box
      className="fixed inset-0 flex justify-center items-start pt-[25vh] bg-black bg-opacity-50" // Adjust vertical positioning
    >
      <Card
        sx={{
          width: { xs: "90%", sm: "50%", md: "30vw", lg: "25vw" }, // Responsive width
          padding: { xs: 2, sm: 2, md: 2 }, // Responsive padding
          borderRadius: "6px", // Adjust border radius
          margin: { xs: 2, sm: 3 }, // Margin for smaller screens
        }}
      >
        <Typography
          variant="h6"
          sx={{
            color: "#001F68",
            marginBottom: "20px",
            marginTop: "0",
            fontWeight: "600",
            lineHeight: "25px",
            textAlign: "center", // Center text on smaller screens
          }}
        >
          {props.text}
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" }, // Stack buttons on small screens
            gap: 2, // Gap between buttons
            width: "100%",
          }}
        >
          <Button
            variant="outlined"
            color="primary"
            onClick={props.onClose}
            sx={{
              borderRadius: "4px",
              flex: 1,
              borderColor: "#3183FF", // Set border color
              color: "#3183FF", // Set text color
              "&:hover": {
                borderColor: "#3183FF", // Maintain border color on hover
                backgroundColor: "rgba(49, 131, 255, 0.1)", // Optional: add hover effect
              },
            }}
          >
            Go Back
          </Button>
          <Button
            variant="contained"
            color="error"
            sx={{
              borderRadius: "4px",
              flex: 1,
              "&:hover": {
                backgroundColor: "#C62828", // Optional: darker shade on hover
              },
            }}
            // onClick={opentimecard}
          >
            Delete
          </Button>
        </Box>
      </Card>
    </Box>
  );
}
