import React, { useState, useEffect, useMemo, useRef } from "react";
import {
  Card,
  Typography,
  TextField,
  Button,
  FormControl,
  Checkbox,
  Chip,
  Paper,
  ListItem,
  ListItemText,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import back from "assets/svg/prev-black.svg";
import { useNavigate } from "react-router-dom";
import axiosHttp from "_utils/axios.index";
import { ApiConstants } from "_utils/api-constants";
import { QuestionsforCreateSection } from "_utils/interface";
import debounce from "lodash/debounce";
import {
  FixedSizeList as VirtualizedList,
  ListChildComponentProps,
} from "react-window";

interface RequestBody {
  set_type: string;
  title: string;
  number_of_easy_problems: number | null;
  number_of_medium_problems: number | null;
  number_of_hard_problems: number | null;
  questions_ids: string[];
}

function CreateSetCard(props: { onClose: () => void; set_type: string }) {
  const navigate = useNavigate();
  const [setName, setSetName] = useState("");
  const [questions, setQuestions] = useState<string[]>([]);
  const [allProblems, setAllProblems] = useState<QuestionsforCreateSection[]>(
    []
  );
  const [filteredProblems, setFilteredProblems] = useState<
    QuestionsforCreateSection[]
  >([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement | null>(null);
  const [difficultyFilter, setDifficultyFilter] = useState<string>("all");
  const [topicFilter, setTopicFilter] = useState<string>("");

  const fetchProblems = async () => {
    try {
      const [problemsRes, singleMcqsRes, multiMcqsRes, subjectiveQuesRes] =
        await Promise.all([
          axiosHttp.get(ApiConstants.assessment.getallproblems()),
          axiosHttp.get(ApiConstants.assessment.getallsinglemcqs()),
          axiosHttp.get(ApiConstants.assessment.getallmultimcqs()),
          axiosHttp.get(ApiConstants.assessment.getallsubjectiveques()),
        ]);

      // Standardize the responses to match the QuestionsforCreateSection interface
      const standardizedProblems = problemsRes.data.map((problem: any) => ({
        pk: problem.pk,
        title: problem.title,
        topic: problem.topics?.toString() || null, // Convert topics to string
        difficulty_level: problem.difficulty_level,
      }));

      const standardizedSingleMcqs = singleMcqsRes.data.map((mcq: any) => ({
        pk: mcq.pk,
        title: mcq.question_text,
        topic: mcq.topic || null, // Handle undefined or null topics
        difficulty_level: mcq.difficulty_level,
      }));

      const standardizedMultiMcqs = multiMcqsRes.data.map((mcq: any) => ({
        pk: mcq.pk,
        title: mcq.question_text,
        topic: mcq.topic || null,
        difficulty_level: mcq.difficulty_level,
      }));

      const standardizedSubjectiveQues = subjectiveQuesRes.data.map(
        (subjective: any) => ({
          pk: subjective.pk,
          title: subjective.title,
          topic: null, // Subjective questions don't have topics
          difficulty_level: subjective.difficulty_level,
        })
      );

      // Combine all standardized arrays
      const allProblemsData = [
        ...standardizedProblems,
        ...standardizedSingleMcqs,
        ...standardizedMultiMcqs,
        ...standardizedSubjectiveQues,
      ];

      // Update state
      setAllProblems(allProblemsData);
      setFilteredProblems(allProblemsData);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    return () => {
      fetchProblems();
    };
  }, []);

  const difficultyMapping = {
    1: "easy",
    2: "medium",
    3: "hard",
  };

  const debouncedSearch = useMemo(
    () =>
      debounce((term: string, difficulty: string, topic: string) => {
        let filtered = allProblems;

        // Filter by search term
        if (term !== "") {
          filtered = filtered.filter((problem) =>
            problem.title?.toLowerCase().startsWith(term.toLowerCase())
          );
        }

        // Filter by difficulty
        if (difficulty !== "all") {
          filtered = filtered.filter(
            (problem) =>
              difficultyMapping[
                problem.difficulty_level as keyof typeof difficultyMapping
              ] === difficulty
          );
        }

        // Filter by topic
        if (topic !== "") {
          filtered = filtered.filter((problem) => {
            const topics = Array.isArray(problem.topic)
              ? problem.topic.map((t) => t.toLowerCase())
              : [problem.topic?.toString().toLowerCase() || ""];
            return topics.some((t) => t.startsWith(topic.toLowerCase()));
          });
        }

        setFilteredProblems(filtered);
      }, 300),
    [allProblems]
  );

  useEffect(() => {
    debouncedSearch(searchTerm, difficultyFilter, topicFilter);
  }, [searchTerm, difficultyFilter, topicFilter, debouncedSearch]);

  const handleCreateSet = async () => {
    // Create the request body
    const requestBody: RequestBody = {
      set_type: props.set_type,
      title: setName,
      number_of_easy_problems: null,
      number_of_medium_problems: null,
      number_of_hard_problems: null,
      questions_ids: questions,
    };

    // Calculate the number of problems based on their difficulty levels
    questions.forEach((questionId) => {
      const problem = allProblems.find((p) => p.pk === questionId);
      if (problem) {
        switch (problem.difficulty_level) {
          case 1:
            requestBody.number_of_easy_problems =
              (requestBody.number_of_easy_problems || 0) + 1;
            break;
          case 2:
            requestBody.number_of_medium_problems =
              (requestBody.number_of_medium_problems || 0) + 1;
            break;
          case 3:
            requestBody.number_of_hard_problems =
              (requestBody.number_of_hard_problems || 0) + 1;
            break;
          default:
            break;
        }
      }
    });

    // Make the POST request
    try {
      // await axiosHttp.post(ApiConstants.assessment.createSet(), requestBody);
      await axiosHttp.post(ApiConstants.assessment.createset(), requestBody);

      // Handle success (e.g., navigate to another page or show a success message)
      props.onClose();
    } catch (error) {
      console.error("Error creating set:", error);
      // Handle error (e.g., show an error message)
    }
  };

  const handleDropdownToggle = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const textFieldStyle = {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#D7E8FF",
      },
      "&:hover fieldset": {
        borderColor: "#D7E8FF",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#D7E8FF",
      },
    },
    "& .MuiInputLabel-root": {
      color: "black",
      fontSize: "14px",
    },
  };

  const isStepComplete = () => {
    return setName !== "" && questions.length > 0;
  };

  const renderProblem = ({ index, style }: ListChildComponentProps) => {
    const problem = filteredProblems[index];
    const topics = Array.isArray(problem.topic)
      ? problem.topic.join(", ")
      : problem.topic?.toString() || "";
    const problemInfo = `${topics} | ${
      problem.difficulty_level === 1
        ? "Easy"
        : problem.difficulty_level === 2
        ? "Medium"
        : "Hard"
    } | ${problem.title}`;

    return (
      <ListItem
        key={problem.pk}
        button
        // onClick={() => setQuestions((prev) => [...prev, problem.pk])}
        onClick={(e) => {
          e.stopPropagation();
          setQuestions((prev) =>
            prev.includes(problem.pk)
              ? prev.filter((q) => q !== problem.pk)
              : [...prev, problem.pk]
          );
        }}
        style={{
          ...style,
          height: "auto",
          padding: "8px 8px",
          overflow: "hidden",
          whiteSpace: "normal",
          textOverflow: "ellipsis",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Checkbox
          checked={questions.includes(problem.pk)}
          onClick={(e) => {
            e.stopPropagation();
            setQuestions((prev) =>
              prev.includes(problem.pk)
                ? prev.filter((q) => q !== problem.pk)
                : [...prev, problem.pk]
            );
          }}
          style={{ padding: "0 8px 0 0" }}
        />
        <ListItemText
          primary={problemInfo}
          sx={{
            lineHeight: "1.2",
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: "-webkit-box",
            WebkitLineClamp: 2,
            WebkitBoxOrient: "vertical",
          }}
        />
      </ListItem>
    );
  };

  const uniqueTopics = useMemo(() => {
    const topics = new Set<string>();
    allProblems.forEach((problem) => {
      if (Array.isArray(problem.topic)) {
        problem.topic.forEach((topic) => topics.add(topic));
      } else if (typeof problem.topic === "string") {
        topics.add(problem.topic);
      }
    });
    return Array.from(topics);
  }, [allProblems]);

  const [selectedDifficulty, setSelectedDifficulty] = useState("all");

  const handleDifficultySelect = (difficulty: string) => {
    selectedDifficulty === difficulty
      ? setDropdownOpen(false)
      : setDropdownOpen(true);
    setSelectedDifficulty((prevDifficulty) =>
      prevDifficulty === difficulty ? "all" : difficulty
    );
    // setDifficultyFilter(difficulty === "all" ? "all" : difficulty);
    setDifficultyFilter((prevDifficulty) =>
      prevDifficulty === difficulty ? "all" : difficulty
    );
  };

  const difficultyButtonStyle = (difficulty: string) => ({
    border: "1px solid #D7E8FF",
    borderRadius: "0px",
    color: "black",
    backgroundColor: selectedDifficulty === difficulty ? "#E6F0FF" : "white",
    height: "55px",
    fontWeight: "400",
    "&:hover": {
      backgroundColor: "#E6F0FF",
      // borderColor: "#D7E8FF",
    },
  });

  const handleOkay = () => {
    setDropdownOpen(false);
    // Add any additional logic for "Okay" button
  };

  const handleCancel = () => {
    setDropdownOpen(false);
    // Add any additional logic for "Cancel" button
  };

  return (
    <Card className="md:w-[470px] w-[90%] h-[80%] mt-14 rounded-2xl flex flex-col absolute top-[45%] left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-20 p-6">
      <div className="flex justify-between items-center mb-8">
        <div
          className="flex items-center cursor-pointer"
          onClick={props.onClose}
        >
          <span className="h-full">
            <img src={back} className="mr-2 h-4" alt="" />
          </span>
          <span className="text-secondary-500 text-sm">
            Back to Create Section
          </span>
        </div>
        <CloseIcon onClick={props.onClose} className="cursor-pointer" />
      </div>
      <div className="flex justify-between flex-row mb-6">
        <Typography variant="h6" sx={{ color: "#001F68", fontWeight: "600" }}>
          Create Set
        </Typography>
        <Button
          disabled
          variant="outlined"
          style={{
            backgroundColor: "#D7E8FF",
            borderColor: "#87B8FF",
            color: "black",
            borderRadius: "1rem",
            padding: "2px 12px",
            fontSize: "0.75rem",
            textTransform: "none",
          }}
        >
          {props.set_type === "M"
            ? "MCQ"
            : props.set_type === "C"
            ? "Coding"
            : "Subjective"}
        </Button>
      </div>

      <div className="flex-grow">
        <Typography variant="body2">
          Set name <span className="text-red-500">*</span>
        </Typography>
        <TextField
          fullWidth
          placeholder="Enter set title"
          value={setName}
          onChange={(e) => setSetName(e.target.value)}
          required
          sx={{ ...textFieldStyle, marginBottom: "16px" }}
        />

        <div className="flex flex-row space-x-4 w-full">
          <FormControl margin="normal" sx={textFieldStyle} className="w-[65%]">
            <Typography sx={{ fontSize: "14px", marginTop: "-20px" }}>
              Filter by Topic Name
            </Typography>
            <TextField
              placeholder="Search Topics"
              value={topicFilter}
              onChange={(e) => setTopicFilter(e.target.value)}
              fullWidth
              onClick={handleDropdownToggle}
            />
          </FormControl>

          <FormControl
            fullWidth
            margin="normal"
            sx={{ ...textFieldStyle, height: "100%" }}
          >
            <Typography sx={{ fontSize: "14px", marginTop: "-20px" }}>
              Filter by Difficulty
            </Typography>
            <div className="flex h-full">
              <Button
                onClick={() => handleDifficultySelect("easy")}
                sx={difficultyButtonStyle("easy")}
              >
                Easy
              </Button>
              <Button
                onClick={() => handleDifficultySelect("medium")}
                sx={difficultyButtonStyle("medium")}
              >
                Medium
              </Button>
              <Button
                onClick={() => handleDifficultySelect("hard")}
                sx={difficultyButtonStyle("hard")}
              >
                Hard
              </Button>
            </div>
          </FormControl>
        </div>
        <Typography variant="body2">
          Choose the questions <span className="text-red-500">*</span>
        </Typography>
        <FormControl fullWidth margin="normal" sx={textFieldStyle}>
          <TextField
            placeholder={questions.length === 0 ? "Search questions" : ""}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            fullWidth
            onClick={handleDropdownToggle}
            multiline
            minRows={1}
            maxRows={2} // Allow for up to two lines of chips
            InputProps={{
              startAdornment: questions.map((pk) => {
                const problem = allProblems.find((p) => p.pk === pk);
                return (
                  <Chip
                    key={pk}
                    label={
                      problem
                        ? `${problem.topic} | Difficulty: ${problem.difficulty_level} | ${problem.title}`
                        : pk
                    }
                    onDelete={() =>
                      setQuestions(questions.filter((q) => q !== pk))
                    }
                    style={{
                      margin: "4px",
                      backgroundColor: "#D7E8FF",
                      border: "1px solid #AFD0FF",
                      color: "black",
                      borderRadius: "0px",
                      padding: "2px 8px",
                      fontSize: "0.9rem",
                      maxWidth: "95%",
                      // display: "inline-block",
                    }}
                  />
                );
              }),
              style: {
                display: "flex",
                flexWrap: "wrap", // Allow chips to wrap
                overflow: "auto", // Make it scrollable if it overflows
                maxHeight: "100px", // Adjust height based on your design
                marginBottom: questions.length > 1 ? "8px" : "0", // Add spacing if chips are present
              },
            }}
            style={{
              maxHeight: "100px", // Fixed height to accommodate 2 lines of chips and 1 line of input
            }}
          />

          <div ref={dropdownRef} />
        </FormControl>
        {dropdownOpen && (
          <Paper
            style={{
              maxHeight: "175px",
              overflow: "auto",
            }}
          >
            <VirtualizedList
              height={300}
              itemCount={filteredProblems.length}
              itemSize={55}
              width="100%"
              className="scrollbar-thin scrollbar-thumb-blue-500 scrollbar-track-blue-300"
            >
              {renderProblem}
            </VirtualizedList>
            <div className="flex justify-end p-2">
              <Button
                onClick={handleCancel}
                sx={{ marginRight: 1, color: "black", borderColor: "#D7E8FF" }}
                variant="outlined"
              >
                Cancel
              </Button>
              <Button
                onClick={handleOkay}
                sx={{ backgroundColor: "#3183FF", color: "white" }}
                variant="contained"
              >
                Okay
              </Button>
            </div>
          </Paper>
        )}
      </div>

      <div className="flex justify-end">
        <Button
          variant="contained"
          fullWidth
          style={{
            backgroundColor: isStepComplete() ? "#3183FF" : "#AFD0FF",
            color: "white",
            cursor: isStepComplete() ? "pointer" : "not-allowed",
            width: "100%",
            boxShadow: "none",
            margin: "4px 0 0 0",
          }}
          onClick={handleCreateSet}
        >
          Create Set
        </Button>
      </div>
    </Card>
  );
}

export default CreateSetCard;
