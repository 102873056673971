import React, { useState } from "react";
import {
  Card,
  Typography,
  TextField,
  Button,
  InputAdornment,
  Box,
} from "@mui/material";
import SaveCard from "./save";
import { CreateAssessmentCardProps } from "_utils/interface";

const textFieldStyle = {
  "& .MuiOutlinedInput-root": {
    height: "40px", // Reduced height
    "& fieldset": {
      borderColor: "#D7E8FF",
    },
    "&:hover fieldset": {
      borderColor: "#D7E8FF",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#D7E8FF",
    },
  },
  "& .MuiInputLabel-root": {
    color: "black",
    fontSize: "14px",
    transform: "translate(0, -24px) scale(1)",
  },
  "& .MuiInputBase-input": {
    padding: "8px 14px", // Adjust padding to center text vertically
  },
  "& .MuiInputBase-input::placeholder": {
    color: "#D7E8FF",
  },
};

export default function TimeCard(props: {
  onClose: () => void;
  onSave: (duration: string) => void;
  togglesavecard: () => void;
}) {
  const handlesavecard = () => {
    const duration = String(Number(hours) * 60 + Number(minutes));
    props.onSave(duration);
    props.togglesavecard();
    props.onClose();
  };
  const [hours, setHours] = useState<number | string>("");
  const [minutes, setMinutes] = useState<number | string>("");
  const [savecard, setsavecard] = useState(false);
  // const closesavecard = () => {
  //   setsavecard(false);
  // };

  return (
    <Box
      className="fixed inset-0 flex justify-center items-start pt-[25vh] bg-black bg-opacity-50 z-[10001]" // Adjust vertical positioning
    >
      <Card
        sx={{
          width: { xs: "90%", sm: "50%", md: "25%" }, // Responsive width
          padding: { xs: 2, sm: 3, md: 3 }, // Responsive padding
          borderRadius: "6px", // Adjust border radius
          margin: { xs: 2, sm: 3 }, // Margin for smaller screens
        }}
      >
        <Typography
          variant="h6"
          sx={{
            color: "#001F68",
            marginBottom: "12px",
            marginTop: "-8px",
            fontWeight: "600",
            lineHeight: "25px",
            textAlign: "center", // Center text on smaller screens
          }}
        >
          Enter the time for assessment
        </Typography>

        <Box className="flex space-x-4 mb-5">
          <TextField
            placeholder="Enter in HH"
            type="number"
            value={hours}
            onChange={(e) => {
              const value = e.target.value;
              setHours(value);
            }}
            sx={{
              ...textFieldStyle,
              width: "50%",
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end" sx={{ color: "black" }}>
                  Hrs
                </InputAdornment>
              ),
              inputProps: {
                min: 0,
                style: { textAlign: "left" },
              },
            }}
          />
          <TextField
            placeholder="Enter in MM"
            value={minutes}
            onChange={(e) => {
              const value = e.target.value;
              setMinutes(value);
            }}
            sx={{
              ...textFieldStyle,
              width: "50%",
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end" sx={{ color: "black" }}>
                  Mins
                </InputAdornment>
              ),
              inputProps: {
                min: 0,
                style: { textAlign: "left" },
              },
            }}
          />
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" }, // Stack buttons on small screens
            gap: 2, // Gap between buttons
            width: "100%",
            marginTop: 2,
            marginBottom: -1,
          }}
        >
          <Button
            variant="outlined"
            color="primary"
            onClick={props.onClose}
            sx={{
              borderRadius: "4px",
              borderColor: "#3183FF", // Set border color
              color: "#3183FF", // Set text color
              flex: 1,
              "&:hover": {
                borderColor: "#3183FF", // Maintain border color on hover
                backgroundColor: "rgba(49, 131, 255, 0.1)", // Optional: add hover effect
              },
            }}
          >
            Go Back
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handlesavecard}
            sx={{
              borderRadius: "4px",
              backgroundColor: "#3183FF", // Set background color
              color: "#FFFFFF", // Set text color to white for contrast
              flex: 1,
              "&:hover": {
                backgroundColor: "#2576D9", // Optional: darker shade on hover
              },
            }}
          >
            Save
          </Button>
        </Box>
      </Card>
    </Box>
  );
}
