import { lazy, Suspense } from "react";
import { RouteObject } from "react-router-dom";
import Loading from "pages/Loading";
import PageNotFound from "pages/404";
import AuthorizedRoute from "routes/AuthorizedRoute";
import ManageAssessment from "pages/ManageAssessment/index";
import AssessmentDetails from "pages/ManageAssessment/AssessmentDetails";
import CreateAssessment from "pages/CreateAssessment";

const Loadable = (Component: any) => (props: JSX.IntrinsicAttributes) =>
(
  <Suspense fallback={<Loading />}>
    <Component {...props} />
  </Suspense>
);

const AssessmentLinks = Loadable(lazy(() => import("pages/AssessmentLink")));
const Login = Loadable(lazy(() => import("pages/Login")));
const AssessmentPageLink = Loadable(lazy(() => import("pages/AssessmentPage")));
const OpenAssessmentLink = Loadable(
  lazy(() => import("pages/AssessmentPage/OpenAssessment"))
);
const AssessmentStart = Loadable(
  lazy(() => import("pages/AssessmentPage/Assessment"))
);
const AssessmentResult = Loadable(
  lazy(() => import("pages/AssessmentPage/AssessmentResult"))
);
const AssessmentReportPage = Loadable(lazy(() => import("pages/AssessmentReport/index")));


const routes: RouteObject[] = [
  {
    path: "/",
    index: true,
    element: <Login />,
  },
  // {
  //     path: "my-admin-dashboard",
  //     element: <AuthorizedRoute />,
  //     children: [
  //         {
  //             path: "",
  //             element: <AdminDashboard />
  //         }
  //     ]
  // },

  {
    path: "assessment",
    element: <AuthorizedRoute />,
    children: [
      {
        path: "",
        element: <AssessmentLinks />,
      },
      {
        path: ":id",
        element: <AssessmentPageLink />,
      },
    ],
  },
  {
    path: "manage-assessment",
    element: <AuthorizedRoute />,
    children: [
      { path: "", element: <ManageAssessment /> },
      { path: ":id", element: <AssessmentDetails /> },
    ],
  },
  {
    path: "assessment-report/:email/:test_id/:submission_id",
    element: <AssessmentReportPage />
  },   
  {
    path: "create-assessment",
    element: <AuthorizedRoute />,
    children: [{ path: ":id", element: <CreateAssessment /> }],
  },
  {
    path: "assessment/online-assessment/test-id/:id",
    element: <OpenAssessmentLink />,
  },
  {
    path: "assessment/online-assessment/test-id/:id/start",
    element: <AssessmentStart />,
  },
  {
    path: "assessment/online-assessment/test-id/:id/result",
    element: <AssessmentResult />,
  },
  {
    path: "login",
    element: <Login />,
  },
  {
    path: "*",
    element: <PageNotFound />,
  },
];

export default routes;
