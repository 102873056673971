import { useEffect, useState, useCallback } from "react";
import { useIdleTimer } from "react-idle-timer";
import axiosHttp from "_utils/axios.index";
import { AxiosResponse } from "axios";
import { ApiConstants } from "_utils/api-constants";
import { useAuth } from "hooks/AuthProvider";

function IdleTimerComponent() {
    const userAuth = useAuth();
    const [state, setState] = useState<string>('Active');
    const [remaining, setRemaining] = useState<number>(300);
    const isLoggedIn = !!userAuth.token;
    const keepAliveInterval = 5 * 60 * 1000;
    const callIdleApi = useCallback(() => {
        if (isLoggedIn) {
            return axiosHttp.post(ApiConstants.sessions.stop()).then((res: AxiosResponse) => {
                // console.info('Idle API called:', res);
            }).catch(error => {
                console.error('Error calling Idle API:', error);
            });
        }
    }, [isLoggedIn]);

    const callActiveApi = useCallback(() => {
        if (isLoggedIn) {
            return axiosHttp.post(ApiConstants.sessions.start()).then((res: AxiosResponse) => {
                // console.info('Active API called:', res);
                if (res.data.message === "Running session present") {
                    killAll();
                }
            }).catch(error => {
                console.error('Error calling Active API:', error);
            });
        }
    }, [isLoggedIn]);

    const isAlive = useCallback(() => {
        if (isLoggedIn) {
            return axiosHttp.post(ApiConstants.sessions.keep_alive()).then((res: AxiosResponse) => {
             
            }).catch(error => {
                console.error('Error calling Active API:', error);
            });
        }
    }, [isLoggedIn]);

    const killAll = useCallback(() => {
        if (isLoggedIn) {
            return axiosHttp.post(ApiConstants.sessions.killAll()).then((res: AxiosResponse) => {
                // console.info('KillAll API called:', res);
                callActiveApi();
            }).catch(error => {
                console.error('Error calling KillAll API:', error);
            });
        }
    }, [isLoggedIn, callActiveApi]);

    const onIdle = useCallback(() => {
        if (isLoggedIn && remaining === 0) {
            setState('Idle');
            callIdleApi();
        }
    }, [isLoggedIn, remaining, callIdleApi]);

    const onActive = useCallback(() => {
        if (isLoggedIn) {
            setState('Active');
            callActiveApi();
        }
    }, [isLoggedIn, callActiveApi]);

    const handleAction = useCallback(() => {
        if (isLoggedIn) {
            if (remaining === 0) {
                callIdleApi();
            } else {
                reset();
            }
        }
    }, [remaining, isLoggedIn, callIdleApi]);

    const { getRemainingTime, reset } = useIdleTimer({
        onActive,
        onIdle,
        onAction: handleAction,
        timeout: 300000,
        throttle: 500,
    });

    useEffect(() => {
        let isMounted = true;

        if (isLoggedIn && isMounted) {
            callActiveApi()
        }

        return () => {
            isMounted = false;
        };
    }, [isLoggedIn, callActiveApi]);

    useEffect(() => {
        const interval = setInterval(() => {
            setRemaining(Number((getRemainingTime() / 1000).toFixed(0)));
        }, 1000);
        return () => clearInterval(interval);
    }, [getRemainingTime]);

    useEffect(() => {
        const handleBeforeUnload = async () => {
            if (isLoggedIn) {
                await syncApicall();
                await callIdleApi();
            }
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [isLoggedIn, callIdleApi]);

    useEffect(() => {
        if (isLoggedIn && state === 'Active' && remaining === 0) {
            callIdleApi();
        }
    }, [isLoggedIn, state, remaining, callIdleApi]);

    const syncApicall = useCallback(async () => {
        if (isLoggedIn) {
            const xhr = new XMLHttpRequest();
            xhr.open('POST', ApiConstants.sessions.stop(), false);
            xhr.setRequestHeader('Content-Type', 'application/json;charset=UTF-8');
            xhr.setRequestHeader('Authorization', `Bearer ${userAuth.token}`);
            try {
                xhr.send();
            } catch (e) {
                console.error('Failed to send synchronous request:', e);
            }
        }
    }, [isLoggedIn]);

    useEffect(() => {
        if (isLoggedIn && state === 'Active') {
            const intervalId = setInterval(() => {
                isAlive();
            }, keepAliveInterval);

            return () => clearInterval(intervalId);
        }
    }, [isLoggedIn, state]);
    return <div></div>;
}

export default IdleTimerComponent;
