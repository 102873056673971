import { Card, Typography, Button, Box } from "@mui/material";

export default function SaveCard(props: {
  onClose: () => void;
  onSubmit: () => void;
}) {
  return (
    <Box
      className="fixed inset-0 flex justify-center items-start pt-[25vh] bg-black bg-opacity-50 z-[10000]" // Adjust vertical positioning
    >
      <Card
        sx={{
          width: { xs: "90%", sm: "50%", md: "25%" }, // Responsive width
          padding: { xs: 2, sm: 3, md: 2 }, // Responsive padding
          borderRadius: "6  px", // Adjust border radius
          margin: { xs: 2, sm: 3 }, // Margin for smaller screens
        }}
      >
        <Typography
          variant="h6"
          sx={{
            color: "#001F68",
            marginBottom: "25px",
            marginTop: "0",
            fontWeight: "600",
            lineHeight: "1",
            textAlign: "center", // Center text on small screens, left on larger screens
          }}
        >
          Are you sure you want to save this assessment?
        </Typography>
        <Box
          sx={{
            height: { xs: "34px" },
            display: "flex",
            flexDirection: { xs: "column", sm: "row" }, // Stack buttons on small screens
            gap: 2, // Gap between buttons
            width: "100%",
          }}
        >
          <Button
            variant="outlined"
            color="primary"
            onClick={props.onClose}
            sx={{
              flex: 1,
              borderRadius: "0.5rem",
              borderColor: "#3183FF", // Set border color
              color: "#3183FF", // Set text color
              padding: { xs: "10px", sm: "12px" }, // Responsive padding
              "&:hover": {
                borderColor: "#3183FF", // Maintain border color on hover
                backgroundColor: "rgba(49, 131, 255, 0.1)", // Optional: add hover effect
              },
            }}
          >
            Go Back
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              props.onSubmit();
              props.onClose();
            }}
            sx={{
              flex: 1,
              borderRadius: "0.5rem",
              backgroundColor: "#3183FF", // Set background color
              color: "#FFFFFF", // Set text color to white for contrast
              padding: { xs: "10px", sm: "12px" }, // Responsive padding
              "&:hover": {
                backgroundColor: "#2576D9", // Optional: darker shade on hover
              },
            }}
          >
            Save
          </Button>
        </Box>
      </Card>
    </Box>
  );
}
